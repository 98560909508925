import routes from '~/constants/routes';

import type { FetcherWithComponents } from '@remix-run/react';
import type { ApiResponse, FlipResponse, Mixtape, MixtapeResponse, Pagination, RouteTyping, Topic } from 'types';

export const loadMoreTopics = (
  groupId: string | number,
  fetcher: FetcherWithComponents<ApiResponse<Topic[]>>,
  pagination?: Pagination,
) => {
  if (pagination) {
    const qs = new URLSearchParams([['page', (pagination.current_page + 1).toString()]]);
    qs.append('_action', 'justTopicsLoad');
    fetcher.load(routes.GROUPS_ID_TOPICS_FUNC(groupId) + `?${qs}`);
  }
};

export const loadMoreTopicVideos = (
  groupId: string | number,
  topicId: string | number,
  fetcher: FetcherWithComponents<ApiResponse<FlipResponse[]>>,
  pagination?: Pagination,
) => {
  if (pagination) {
    const qs = new URLSearchParams([['page', (pagination.current_page + 1).toString()]]);
    fetcher.load(routes.GROUPS_ID_TOPICS_ID_RESPONSES_FUNC(groupId, topicId) + `?${qs}`);
  }
};

export const loadMoreMixtapeVideos = (
  mixtape: Mixtape,
  fetcher: FetcherWithComponents<ApiResponse<MixtapeResponse[]>>,
  pagination?: Pagination,
) => {
  if (pagination) {
    const qs = new URLSearchParams([['page', (pagination.current_page + 1).toString()]]);
    qs.append('_action', 'loadMoreMixtapeResponses');
    fetcher.load(routes.MIXTAPES_ID_URL_FUNC(mixtape.id) + `?${qs}`);
  }
};

export const loadMoreMixtapes = (fetcher: FetcherWithComponents<ApiResponse<Mixtape[]>>, pagination?: Pagination) => {
  if (pagination) {
    const qs = new URLSearchParams([['page', (pagination.current_page + 1).toString()]]);
    qs.append('_action', 'loadMoreMixtapes');
    fetcher.load(routes.MIXTAPES + `?${qs}`);
  }
};

export const loadMoreMyVideos = (fetcher: FetcherWithComponents<RouteTyping<'MyVideos'>>, pagination?: Pagination) => {
  if (pagination) {
    const qs = new URLSearchParams([['page', (pagination.current_page + 1).toString()]]);
    fetcher.load(routes.MY_VIDEOS + `?${qs}`);
  }
};
